<template>
  <section id="front_step">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 m-auto">
          <div class="title_header">
            <h2>
              কেন আমরা সবার চেয়ে <span style="color: #ff1e1e">এগিয়ে</span>
            </h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-xl-4 col-md-6 col-lg-4"
          v-for="(steps, index) in front_steps"
          :key="index"
        >
          <div class="step_card h-100">
            <div class="step_img text-center mb-3">
              <img width="70" :src="steps.img" alt="step_img" />
            </div>
            <div class="step_card_content">
              <p>{{ steps.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row front_step_testimonial">
        <div
          class="col-xl-6 col-12 col-md-6 col-lg-6"
          v-for="(skill, num) in skills"
          :key="num"
        >
          <div class="card_testimonial">
            <div class="row">
              <div class="col-xl-2 col-12">
                <div class="card_testimonial_img">
                  <img :src="skill.src" alt="img" />
                  <div class="card_testimonial_quote">
                    <img
                      src="https://www.brightskills.com/_nuxt/img/quote.dde38d0.svg"
                      alt="qoute"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xl-10 col-12">
                <div class="card_testimonial_description">
                  <h5>{{ skill.title }}</h5>
                  <p>{{ skill.desp }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      front_steps: [
        {
          img: `https://admin.brightskills.com/media/homepage/skill/Group_9074.png`,
          text: `সফট-স্কিল বৃদ্ধি থেকে শুরু করে ক্যারিয়ার ডেভেলপমেন্ট- নিজেকে মানসম্পন্ন করে তোলার এ-টু-জেড সিলেবাস`,
        },
        {
          img: `https://admin.brightskills.com/media/homepage/skill/Group_9073.png`,
          text: `সবচেয়ে দক্ষ প্রশিক্ষকগণ সবচেয়ে সহজ উপায়ে লেসন তৈরি করেন`,
        },
        {
          img: `https://admin.brightskills.com/media/homepage/skill/Group_9072.png`,
          text: `মেধা যাচাইয়ের জন্য কুইজ ও এক্সামের ব্যবস্থা। কোর্স শেষে সনদ প্রদান।`,
        },
      ],
      skills: [
        {
          src: "https://admin.brightskills.com/media/homepage/skill/sttudent_image.png",
          title: "স্কিলড হওয়া যায় যখন তখন",
          desp: `প্রথাগতভাবে আমরা স্কুল-কলেজে গিয়ে আমাদের শিক্ষা গ্রহন করি। কিন্তু অনলাইন মাধ্যমের কল্যানে এখন সহজেই যেকোন সময়ে যেকোনো
                                    স্থান থেকে শুধুমাত্র ইলেকট্রনিক ডিভাইস ব্যবহারের মাধ্যমে আমাদের দক্ষ হয়ে উঠার সুযোগ অবারিত।`,
        },
        {
          src: "https://admin.brightskills.com/media/homepage/skill/student_pic_1-removebg-preview.png",
          title: "আন্তর্জাতিক মানের কোর্স বাংলাতেই",
          desp: `আমরা সাধারনত বিভিন্ন টিউটোরিয়ালগুলো অনলাইনে ইংরেজীতে পেয়ে থাকি। তবে ব্রাইটস্কিলস প্লাটফর্মে আমরা এখন আন্তর্জাতিক মানের কোর্স সহজে শিখতে পারছি। যার ফলে অনায়াসেই দক্ষ হওয়ার পথ প্রসারিত হচ্ছে।`,
        },
      ],
    };
  },
};
</script>
