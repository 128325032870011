import { render, staticRenderFns } from "./videoPlayer.vue?vue&type=template&id=bd5cd8d0&"
import script from "./videoPlayer.vue?vue&type=script&lang=js&"
export * from "./videoPlayer.vue?vue&type=script&lang=js&"
import style0 from "./videoPlayer.vue?vue&type=style&index=0&id=bd5cd8d0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports