<template>
  <div>
   
    <BannerSec></BannerSec>
    <CourseTabs></CourseTabs>
    <DepartmentCourses></DepartmentCourses>
    <WhyFirst></WhyFirst>
    <PopularCategory></PopularCategory>
    <section id="starting_process">
       <div class="container">
        <div class="process_wrapper">
            <div class="row">
                <div class="col-xl-6 m-auto">
                    <div class="title_header process_header">
                        <h2>
                            কোর্স <span style="color: #ff1e1e ">শুরু</span> করার<span style="color: #ff1e1e "> প্রক্রিয়া</span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3 col-md-6 col-12 col-lg-6" v-for="(item,i) in course_start" :key="i">
                    <div class="card_process">
                        <div class="card_process_img text-center">
                            <img width="70" :src="item.img" alt="image">
                        </div>
                        <div class="card_process_content text-center">
                            <h4>{{ item.title }}</h4>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
       </div>
    </section>
    <!-- student testimonial start-->
    <TestimonialStudent/>
    <!-- student testimonial end-->
    <!---why learn to bright skill start--->
    <section id="why_brightskills">
     <div class="container">
        <div class="row">
            <div class="col-xl-6 m-auto">
                <div class="title_header">
                    <h2>
                        ব্রাইট স্কিলস থেকে কেন কোর্স করবেন 
                    </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-4 col-sm-12 col-md-6 col-lg-6" v-for="(item,i) in course_why" :key="i">
                <div class="why_wrapper">
                    <div class="step_img text-center mb-3">
                        <img width="70" :src="item.img" alt="step_img">
                    </div>
                    <div class="why_content text-center">
                        <h4>{{ item.title }}</h4>
                    </div>
                    <div class="step_card_content">
                        <p>{{ item.description }}</p>
                    </div>
                </div>
            </div>    
        </div>
     </div>
    </section>
    <!---why learn to bright skill end--->
    <!---partner section start--->
    <section id="our_partners">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 m-auto">
                    <div class="title_header">
                        <h2>
                        আমাদের পার্টনারসমূহ
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row partner_marg mx-5">
                <div class="col-xl-2 col-6 col-sm-4 col-md-4 col-lg-2" v-for="(item,i) in partner" :key="i">
                    <div class="partner_wrapper">
                        <img width="100%"  :src="item.img" alt="partner">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!---partner section end--->

  </div>
</template>
<script>

import BannerSec from "@/components/partials/home/BannerSec.vue";
import CourseTabs from "@/components/partials/home/CourseTabs.vue";
import DepartmentCourses from "@/components/partials/home/DepartmentCourses.vue";
import PopularCategory from "@/components/partials/home/PopularCategory.vue";
import WhyFirst from "@/components/partials/home/WhyFirst.vue";
import TestimonialStudent from "@/components/partials/testimonialStudent/TestimonialStudent.vue";

export default {
  components: {
    BannerSec,
    CourseTabs,
    DepartmentCourses,
    WhyFirst,
    PopularCategory,
    TestimonialStudent,
},
data(){
    return {
        course_why:[
            {
             title:"আপডেটেড কোর্স মডিউল",
             description:'সময়োপযোগী কোর্স মডিউল এবং আন্তর্জাতিক কোর্স কারিকুলাম অনুসরণ করে প্রতিটি কোর্স তৈরি করা হয়েছে।',
             img:'https://www.brightskills.com/_nuxt/img/Updated-Module.fc9752a.png'
            },
            {
            title:"স্কিল ডেভেলপমেন্ট",
             description:"প্রতিটি কোর্সে রয়েছে সেলফ এবং ক্যারিয়ার ডেভেলপমেন্টের সুযোগ।",
             img:'https://www.brightskills.com/_nuxt/img/Skills.e7da887.png'
            },
            {
             title:"অভিজ্ঞ ও প্রফেশনাল মেন্টর",
             description:"মার্কেটের অভিজ্ঞ ও প্রফেশনাল মেন্টর কর্তৃক প্রতিটি কোর্স তৈরি করা হয়েছে।",
             img:'https://www.brightskills.com/_nuxt/img/Professional-Mentor.1cae034.png'
            },
            {
             title:"পর্যাপ্ত রিসোর্স সরবরাহ",
             description:"প্রতিটি কোর্সে প্রয়োজনীয় পর্যাপ্ত রিসোর্স সরবরাহ করা হয়েছে।",
             img:'https://www.brightskills.com/_nuxt/img/Resources.50531ab.png'
            },
            {
             title:"সার্টিফিকেশন",
             description:"প্রতিটি কোর্স শেষে রয়েছে সার্টিফিকেট। অনলাইন পোর্টফোলিওতে সার্টিফিকেট অ্যাড করার সুযোগ আছে।",
             img:'https://www.brightskills.com/_nuxt/img/support-2.4369122.svg'
            },
            {
             title:"ক্যারিয়ার সাপোর্ট",
             description:"শিক্ষার্থীদের ক্যারিয়ার ডেভেলপমেন্টের জন্য প্রয়োজনীয় দিকনির্দেশনা দিতে আমাদের রয়েছে ক্যারিয়ার সাপোর্ট টিম।",
             img:'https://www.brightskills.com/_nuxt/img/support-3.6ff0431.svg'
            }
        ],
        course_start:[
            {
                img:"https://www.brightskills.com/_nuxt/img/enroll-1.47e7698.svg",
                title:"অ্যাকাউন্ট তৈরি করুন"
            },
            {
                img:"https://www.brightskills.com/_nuxt/img/enroll-2.c74a9c9.svg",
                title:"কোর্স সিলেক্ট করুন"
            
            },
            {
                img:"https://www.brightskills.com/_nuxt/img/enroll-3.f7561fc.svg",
                title:'পছন্দের কোর্সটি কিনুন'
            },
            {
                img:"https://www.brightskills.com/_nuxt/img/enroll-4.12f2105.svg",
                title:'স্কিল অর্জন শুরু করুন'
            },
            
        ],
        partner:[
            {
                img:"https://www.brightskills.com/_nuxt/img/Concerns_logo-02.6662fea.png"
            },
            {
                img:"https://www.brightskills.com/_nuxt/img/Concerns_logo-04.621d11f.png"
            },
            {
                img:"https://www.brightskills.com/_nuxt/img/Concerns_logo-10.63cb60d.png"
            },
            {
                img:"https://www.brightskills.com/_nuxt/img/Concerns_logo-13.53f370b.png"
            },
            {
                img:"https://www.brightskills.com/_nuxt/img/Concerns_logo-14.f6eb14d.png"
            },
            {
                img:"https://www.brightskills.com/_nuxt/img/Concerns_logo-16.6a7dc2e.png"
            },
        ]
    }
},

};
</script>

<style scoped>
.partner_wrapper {
    height: 100px;
    display: flex;
    align-items: center;
}


</style>